import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class AuthService {
  constructor(private router: Router) {
  }

  async login(): Promise<boolean> {
    this.router.navigate(['/login']);
    return true;
  }

  logout(): Promise<boolean> {
    return this.router.navigate(['/login']);
  }

  async autoLogin(): Promise<boolean> {
    /*todo get local storage*/
    return true;
  }

}
