import {Injectable} from '@angular/core';
import {CanActivate, CanLoad, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {
  authService: AuthService;

  constructor(private router: Router, authService: AuthService) {
    this.authService = authService;
  }

  canLoad(): Promise<boolean> {
    return this.authService.login().then((res: boolean): Promise<boolean> | boolean => {
      if (!res) {
        this.router.navigate(['/login']).then((): boolean => false);
      }
      return true;
    });
  }

  canActivate(): Promise<boolean> {
    return this.authService.autoLogin().then((res: boolean) => {
      if (res) {
        this.router.navigateByUrl('/login').then(() => false);
      }
      return true;
    });
  }
}
