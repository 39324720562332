import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';


const AUTH_API = 'https://api.theomamori.com/api/social/fb/';
//const AUTH_API = 'http://localhost:8080/api/social/fb/';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
  // headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
};

@Injectable({
  providedIn: 'root'
})
export class FacebookAuthService {

  private loginStatus;
  private onSuccess;
  private onFailure;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
  }

  public initializeFacebook() {
    window['FB'].init({
      appId: '3571896963093162',
      status: true,
      xfbml: true,
      cookie: true,
      version: 'v18.0'
    });

    this.getLoginStatus();
  }

  setDialogReference(successCallBack, errorCallBack) {
    this.onSuccess = successCallBack;
    this.onFailure = errorCallBack;
  }

  getLoginStatus() {
    window['FB'].getLoginStatus((response) => {
      console.log('FB===>', response);
      this.loginStatus = response.status;
    });
  }

  login() {
    try {
      if (this.loginStatus !== 'connected') {
        window['FB'].login((response) => {
          console.log('Login response====>', response);
          if (response.status === 'connected') {
            window['FB'].api('/me?fields=name,picture,email,first_name,last_name', (response) => {
              console.log('Successful login for: ', response);
              this.OnSuccess(response);
            });
          }
        }, {scope: 'public_profile,email'});
      }
    } catch (error) {
      this.onFailure(error);
      this.snackBar.open('Something went wrong', 'X', {duration: 1000});
    }
  }

  OnSuccess(response) {
    return this.http.post(AUTH_API + 'signup', {
      data: response,
      dt: moment().toDate()
    }, httpOptions).subscribe(
      (data: any) => {
        this.onSuccess(data);
      },
      (err) => {
        this.onFailure(err);
        this.snackBar.open('Something went wrong', 'X', {duration: 1000});
      }
    );
  }


}
