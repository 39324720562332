import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(public meta: Meta, public title: Title) {}

  setMeta(title, description) {

    if(title){
      this.title.setTitle(title);
    }

    if(description){
      this.meta.updateTag({ name: 'description', value: description });
    }
  }
}
