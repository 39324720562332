import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'errors',
  templateUrl: 'errors.component.html',
  styleUrls: ['errors.component.scss']
})

export class ErrorsComponent implements OnInit {
  @Input() field: any;

  @Output() saveParent = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.field);
  }

  showError(field){
    return Object.keys(field?.errors)?.length === 1
  }

  showSave(): boolean {
    return ((this.field.option?.save) && (this.field.form?.valid && this.field.form.dirty));
  }
}
