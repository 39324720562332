import { Location } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
} from '@angular/router';
import { GoogleAuthService } from '../services/google-auth.service';
//import { FacebookAuthService } from '../services/facebook-auth.service';
import { LinkedInAuthService } from '../services/linkedin-auth.service';
import { LoginComponent } from './pages/auth/login/login.component';
import { SignUpComponent } from './pages/auth/sign-up/sign-up.component';
import { MetaService } from './core/services/meta.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public previousURL: string;

  constructor(
    public eleRef: ElementRef,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private _googleAuthService: GoogleAuthService,
    //private _facebookAuthService: FacebookAuthService,
    private location: Location,
    private _linkedInAuthService: LinkedInAuthService,
    public dialog: MatDialog,
    private _metaService: MetaService
  ) {
    //debugger;
    //TODO restrict scroll to top on browser back
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.includes('/linkedin')) {
          const loginType = window.localStorage.getItem('login');
          if (loginType) {
            if (loginType === 'signup') {
              this.dialog.open(SignUpComponent, {
                panelClass: 'dialog_outer',
                closeOnNavigation: false,
                data: [],
              });
            } else if (loginType === 'signin') {
              this.dialog.open(LoginComponent, {
                panelClass: 'dialog_outer',
                closeOnNavigation: false,
                data: [],
              });
            }
            window.localStorage.removeItem('login');
            this._linkedInAuthService.getAuthCode(event.url);
          }
        }

        if (event.url === this.previousURL) {
          this.previousURL = event.url;
        } else {
          setTimeout(() => {
            this.previousURL = event.url;
            const mainEle = this.eleRef.nativeElement;
            mainEle.scrollTop = 0;
          }, 100);
        }
      }

      if (event instanceof NavigationEnd) {
        const { title, description } =
          this.activatedRoute.snapshot.firstChild.firstChild.data;
        this._metaService.setMeta(title, description);
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this._googleAuthService.initializeGoogleAuth();
      //this._facebookAuthService.initializeFacebook();
    }, 1000);

    const mainEle = this.eleRef.nativeElement;

    mainEle.addEventListener('scroll', () => {
      const header = mainEle.querySelector('app-main-header .header');
      if (header) {
        const headerClass = header.classList;
        mainEle.scrollTop > 20
          ? headerClass.add('scroll')
          : headerClass.remove('scroll');
      }
    });
  }
}
