import {Injectable}              from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable}              from 'rxjs';

const AUTH_API = 'https://api.theomamori.com/api/auth/';
//const AUTH_API = 'http://localhost:8080/api/auth/';

const httpOptions = {
	headers: new HttpHeaders({'Content-Type': 'application/json'})
	// headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
};

@Injectable({
	            providedIn: 'root'
            })
export class AuthService{
	constructor(private http: HttpClient){
	}

	login(email: string, password: string): Observable<any>{

		return this.http.post(AUTH_API + 'signin', {
			email,
			password
		}, httpOptions);
	}

	sendCode(email: string, code: number): Observable<any> {
		return this.http.post(
			AUTH_API + 'sendCode',
			{
				email,
				code,
			},
			httpOptions
		);
	}

	updatePassword(
		email: string,
		password: string,
		code: number
	): Observable<any> {
		return this.http.post(
			AUTH_API + 'updatePassword',
			{
				email,
				password,
				code,
			},
			httpOptions
		);
	}

	register(email: string, password: string, phone: number, dt: object, a: number): Observable<any>{
		return this.http.post(AUTH_API + 'signup', {
			email,
			password,
			phone,
			dt,
			a
		}, httpOptions);
	}
}
