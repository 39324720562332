<div *ngIf="field?.invalid && (field?.dirty || field?.touched)" class="alert-danger">

  <div *ngIf="((field?.errors?.required) && (showError(field)))">
    Required field.
  </div>
  <div *ngIf="field?.errors?.minlength">
    Minimum {{field.errors.minlength.requiredLength}} length is required.
  </div>
  <div *ngIf="field?.errors?.maxlength">
    Maximum {{field.errors.maxlength.requiredLength}} length is required.
  </div>
  <div *ngIf="field?.errors?.matDatepickerParse">
    Wrong date format.
  </div>
  <div *ngIf="field?.errors?.pattern">
    Special character not allowed.
  </div>
  <div *ngIf="field?.errors?.duplicate">
    Duplicate value.
  </div>
  <div *ngIf="field?.errors?.mismatch">Password and confirm password are mismatch.</div>

</div>
