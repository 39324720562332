<div class="main-frame signup">
  <button tabindex="-1" class="close-me" mat-button mat-mini-fab (click)="close()">
    <span class="ev-icon uil-times-circle"></span>
  </button>
  <div class="inner-frame">
    <div class="right-side active">
      <div class="content-top">
        <div class="content-title">
          <span class="content-ball theme-orange"></span>
          <span>Welcome to <strong>Omamori</strong></span>
        </div>
      </div>

      <div class="form-sections" novalidate>
        <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>

          <!--<div class="row-section">
          <div>
            <input
              type="text"
              matInput
              required
              placeholder="Username"
              name="username"
              [(ngModel)]="form.username"
              required
              username
              #username="ngModel"
            />
          </div>
          <div class="alert-danger" *ngIf="username.errors && f.submitted">
            <div *ngIf="username.errors.required">Email is required</div>
            <div *ngIf="username.errors.username">
              Username must be a valid email address
            </div>
          </div>
        </div>-->
          <div class="row-section">
            <div>
              <input type="email" matInput required placeholder="Email" name="email" [(ngModel)]="form.email" required
                email #email="ngModel" />
            </div>
            <div class="alert-danger" *ngIf="email.errors && f.submitted">
              <div *ngIf="email.errors.required">Email is required</div>
              <div *ngIf="email.errors.email">
                Email must be a valid email address
              </div>
            </div>
          </div>

          <div class="row-section">
            <div>
              <input type="password" matInput required placeholder="Password" name="password"
                [(ngModel)]="form.password" required minlength="6" #password="ngModel" />
            </div>
            <div class="alert-danger" *ngIf="password.errors && f.submitted">
              <div *ngIf="password.errors.required">Password is required</div>
              <div *ngIf="password.errors.minlength">
                Password must be at least 6 characters
              </div>
            </div>
          </div>

          <div class="row-section align-center">
            <button mat-raised-button>Sign Up</button>
          </div>

          <div class="alert alert-success" *ngIf="isSuccessful">
            Your registration is successful!
          </div>
          <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
            Signup failed!<br />{{ errorMessage }}
          </div>
          <div class="row-line">
            <span class="lines"></span>
            <span>or</span>
            <span class="lines"></span>
          </div>

          <div class="other-login-options">
            <span>Sign Up with</span>
            <!--<img (click)="loginWithFB()"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Facebook_logo_%28square%29.png/240px-Facebook_logo_%28square%29.png"
              alt="facebook" />
            <span>or</span>-->
            <!--Google signup button auto generate-->
            <div id="google-sigup"></div>
            <span>or</span>
            <img (click)="linkedIn()"
                 src="../../../../assets/images/linkedin.png" alt="linkedin" />
          </div>

          <div class="no-account">
            <span>Already have an account?</span>
            <span class="link mli-8" (click)="goToLogin()">Login</span>
          </div>

          <div class="no-account fs-11">
            <span routerLink="/login">By creating an Omamori account you agree to accept out <a
                (click)="generalNavigation('terms')">Terms of Service</a> & <a
                (click)="generalNavigation('privacy')">Privacy Policy.</a></span>
          </div>
        </form>
      </div>
    </div>
    <div class="left-side">
      <swiper [config]="config">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <span class="bg-img"></span>
            <div class="st-logo">
              <img src="../../../../assets/images/omamori_logo.svg" />
            </div>
          </div>
          <div class="swiper-slide">
            <span class="bg-img"></span>
            <div class="st-logo">
              <img src="../../../../assets/images/omamori_logo.svg" />
            </div>
          </div>
          <div class="swiper-slide">
            <span class="bg-img"></span>
            <div class="st-logo">
              <img src="../../../../assets/images/omamori_logo.svg" />
            </div>
          </div>
          <div class="swiper-slide">
            <span class="bg-img"></span>
            <div class="st-logo">
              <img src="../../../../assets/images/omamori_logo.svg" />
            </div>
          </div>
          <div class="swiper-slide">
            <span class="bg-img"></span>
            <div class="st-logo">
              <img src="../../../../assets/images/omamori_logo.svg" />
            </div>
          </div>
        </div>

        <div class="swiper-pagination"></div>
      </swiper>
    </div>
  </div>
</div>
