import {Injectable}                                     from '@angular/core';
import {HttpClient, HttpEvent, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable}                                     from 'rxjs';

const baseUrl = 'https://api.theomamori.com/api/';
//const baseUrl = 'http://localhost:8080/api/';

@Injectable({
	            providedIn: 'root'
            })
export class StorageS{

	public baseURL         = 'http://dev-omamori-client.s3-website.ap-south-1.amazonaws.com/';
	public PAYMENT         = {STATUS: {PAID: 1, PARTIALLY_PAID: 2}, TYPE: {CASH: 1, CARD: 2, WALLET: 3, ACCOUNT_TRANSFER: 4}};
	public RECORD          = {ACTIVE: 1, IN_ACTIVE: 0};
	public ORDER_STATUS    = {NEW: 1, IN_PROGRESS: 2, APPROVED: 3, REJECT: 4};
	public REGISTRY_TYPE   = {HOME_PAGE: 1, WEDDING_REGISTRY: 2, BABY_REGISTRY: 3, WEBSITE_TEMPLATE: 4, PLANNING_PAGE: 5};
	public REGISTRY_STATUS = {PAID: 2, PENDING: 1};
	public WEBSITE_TAG     = {HOME: 1, EVENTS: 2, FAQ: 3, REGISTRY: 4, PHOTOS: 5, RSVP: 6, TRAVEL: 7, PRODUCT: 8, CART: 9, CART_LIST: 10};
	public STORY           = {BRIDE: 1, GROOM: 2};
	public STR             = {
		REGISTRY_TYPE    : {1: 'Home Page', 2: 'Wedding Registry', 3: 'Baby Registry', 4: 'Website Template', 5: 'Planning Page'},
		PAYMENT          : {1: 'PAID', 2: 'PARTIALLY PAID'}, ORDER_STATUS: {1: 'NEW', 2: 'IN PROGRESS', 3: 'COMPLETED', 4: 'REJECT', 5: 'FULFILLED'},
		REGISTRY_STATUS  : {1: 'PENDING', 2: 'PAID'},
		TYPE             : {1: 'CASH', 2: 'CARD', 3: 'WALLET', 4: 'ACCOUNT TRANSFER'},
		REGISTRY_TYPE_STR: {2: 'wedding-website', 3: 'baby-website'},
		WEBSITE_TAG      : {1: 'ban-home', 2: 'ban-event', 3: 'ban-faq', 4: 'ban-registry', 5: 'ban-photo', 6: 'ban-rsvp', 7: 'ban-travel'},
		WEBSITE_MENU     : {1: 'Home', 2: 'Event', 3: 'FAQ', 4: 'Registry', 5: 'Photos', 6: 'RSVP', 7: 'Travel'},
		FAQ_TYPES        : {MyRegistry: 1, GiftGivers: 2, AccountSettings: 3, WebsiteHelp: 4, FindRegistry: 5, EventPlanning: 6,Search:7}
	};

	constructor(private http: HttpClient){
	}

	getAll(module: string): Observable<any>{
		return this.http.get(`${baseUrl}${module}/fetchAll`);
	}

	/*get(id: number, module: string): Observable<any>{
	  return this.http.get(`${baseUrl}${module}/${id}`);
	}*/

	get(id: number, module: string): Observable<any>{
		return this.http.get(`${baseUrl}${module}/fetchById/${id}`);
	}

	fetchWithVariantById(id: number, module: string): Observable<any>{
		return this.http.get(`${baseUrl}${module}/fetchWithVariantById/${id}`);
	}

	fetchByURL(filter: object, module: string): Observable<any>{
		return this.http.post(`${baseUrl}${module}/fetchByURL`, filter);
	}

	getByCustom(filter: object, module: string): Observable<any>{
		return this.http.post(`${baseUrl}${module}/fetchByCustom`, filter);
	}

	getWebsite(filter: object, module: string): Observable<any>{
		return this.http.post(`${baseUrl}${module}/fetchWebsite`, filter);
	}

	create(data: object, module: string): Observable<any>{
		return this.http.post(`${baseUrl}${module}/create`, data);
	}

	verify(data: object, module: string): Observable<any>{
		return this.http.post(`${baseUrl}${module}/verify`, data);
	}

	update(id: number, data: object, module: string): Observable<any>{
		return this.http.put(`${baseUrl}${module}/updateById/${id}`, data);
	}

	updateRows(data: [], module: string): Observable<any>{
		return this.http.post(`${baseUrl}${module}/updateByIds`, data);
	}

	delete(id: number, module: string): Observable<any>{
		return this.http.delete(`${baseUrl}${module}/deleteById/${id}`);
	}

	deleteRows(ids: any, module: string): Observable<any>{
		return this.http.post(`${baseUrl}${module}/deleteByIds`, ids);
	}

	deleteAll(): Observable<any>{
		return this.http.delete(baseUrl);
	}

	findByTitle(title: string): Observable<any>{
		return this.http.get(`${baseUrl}?title=${title}`);
	}

	pushFileToStorage(file: File, module, fileName): Observable<any>{
		const formdata: FormData = new FormData();

		formdata.append('file', file, fileName);

		const req = new HttpRequest('POST', `${baseUrl}${module}/upload`, formdata, {
			reportProgress: true,
			responseType  : 'text'
		});

		return this.http.request(req);
	}

	getFiles(module): Observable<any>{
		return this.http.get(`${baseUrl}${module}/file`);
	}

	// payment api
	createPayment(data: object): Observable<any>{
		return this.http.post(`${baseUrl}payment/createPayment`, {data: data});
	}

	getPaymentStatus(filter: object): Observable<any>{
		return this.http.post(`${baseUrl}payment/paymentStatus`, filter);
	}

	createRefund(data: object): Observable<any>{
		return this.http.post(`${baseUrl}payment/createRefund`, data);
	}

	getRefundStatus(filter: object): Observable<any>{
		return this.http.post(`${baseUrl}payment/refundStatus`, filter);
	}

}
