import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable}              from '@angular/core';
import {MatSnackBar}             from '@angular/material/snack-bar';
import * as moment               from 'moment';

const AUTH_API = 'https://api.theomamori.com/api/social/linkedin/';
//const AUTH_API = 'http://localhost:8080/api/social/linkedin/';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
    // headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
};

@Injectable({
                providedIn: 'root'
            })
export class LinkedInAuthService{

    private onSuccess;
    private onFailure;
    private authCode;

    constructor(private http: HttpClient, private snackBar: MatSnackBar){ }

  logIn() {
    //Uncomment to test in local
    //const url = `https://linkedin.com/oauth/v2/authorization?client_id=86ss6tz5qv00cg&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Flinkedin&scope=email%20profile%20openid`;
    //Uncomment to deploy in prod
    const url =`https://linkedin.com/oauth/v2/authorization?client_id=86ss6tz5qv00cg&response_type=code&redirect_uri=https%3A%2F%2Ftheomamori.com%2Flinkedin&scope=email%20profile%20openid`;
    window.location.href = url;
  }

    getAuthCode(url){
        const splits  = url.split('=');
        this.authCode = splits[1];
        this.getUserProfile();
    }

    getUserProfile(){
        return this.http.post(AUTH_API + 'signup', {
            authorizationCode: this.authCode,
            dt               : moment().toDate()
        }, httpOptions).subscribe(
            (data: any) => {
                this.onSuccess(data);
            },
            (err) => {
                this.onFailure(err);
                this.snackBar.open('Something went wrong', 'X', {duration: 1000});
            }
        );
    }

    setDialogReference(successCallBack, errorCallBack){
        this.onSuccess = successCallBack;
        this.onFailure = errorCallBack;
    }
}

