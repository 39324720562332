import {Component, OnInit}       from '@angular/core';
import {FormBuilder}             from '@angular/forms';
import {Router}                  from '@angular/router';
import * as moment               from 'moment';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {SwiperOptions}       from 'swiper';
import {AuthService}         from '../../../../services/auth.service';
import {LinkedInAuthService} from '../../../../services/linkedin-auth.service';
import {TokenStorageService} from '../../../../services/token-storage.service';
import {LoginComponent}      from '../login/login.component';
import {GoogleAuthService}   from '../../../../services/google-auth.service';
import {FacebookAuthService} from '../../../../services/facebook-auth.service';

@Component({
               selector   : 'app-sign-up',
               templateUrl: './sign-up.component.html',
               styleUrls  : ['./sign-up.component.scss'],
           })
export class SignUpComponent implements OnInit{

    form: any      = {email: null, password: null, phone: null, a: 1};
    isSuccessful   = false;
    isSignUpFailed = false;
    errorMessage   = '';
    ele: any       = document.querySelector('body');

    config: SwiperOptions = {
        pagination  : {el: '.swiper-pagination', clickable: true},
        navigation  : {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        spaceBetween: 30,
    };

    constructor(
        private formBuilder: FormBuilder, private authService: AuthService, private tokenStorage: TokenStorageService,
        private router: Router, public dialog: MatDialog, private dialogRef: MatDialogRef<SignUpComponent>,
        private _googleAuthService: GoogleAuthService, private _facebookAuthService: FacebookAuthService,
        private _linkedInAuthService: LinkedInAuthService
    ){
    }

    ngOnInit(){
        this._googleAuthService.setDialogReference(this.processRegister.bind(this), this.handleError.bind(this));
        this._facebookAuthService.setDialogReference(this.processRegister.bind(this), this.handleError.bind(this));
        this._linkedInAuthService.setDialogReference(this.processRegister.bind(this), this.handleError.bind(this));
        this._googleAuthService.createButton(
            document.getElementById('google-sigup'),
            () => {
            },
            'signup_with'
        );
    }

    linkedIn(){
        window.localStorage.setItem('login', 'signup');
        this._linkedInAuthService.logIn();
    }

    loginWithFB(){
        this._facebookAuthService.login();
    }

    onSubmit(): void{
        const {email, password, phone, a} = this.form;
        const dt                          = moment().toDate();
        this.authService.register(email, password, phone, dt, a).subscribe(
            (data) => {
                this.processRegister(data);
            },
            (err) => {
                this.handleError(err);
            }
        );
    }

    handleError(err){
        this.errorMessage   = err.error.message;
        this.isSignUpFailed = true;
    }

    processRegister(data){
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.saveSession(this.tokenStorage.USER_KEY, data);
        this.isSuccessful   = true;
        this.isSignUpFailed = false;
        this.close();
        this.router.navigateByUrl('/create-registry');
    }

    loginDialog(){
        this.dialog.open(LoginComponent, {
            panelClass       : 'dialog_outer',
            closeOnNavigation: false,
            data             : [],
            // disableClose: true,
        });
    }

    goToLogin(){
        this.close();
        this.loginDialog();
    }

    close(){
        this.dialogRef.close();
    }

    generalNavigation(type: string){
        this.close();
        this.router.navigateByUrl('/create-registry');

        setTimeout(() => {
            this.router.navigateByUrl('/general-info', {state: {pageType: type}});
            this.ele.scrollIntoView(true);
        }, 100);
    }
}
