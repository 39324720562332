import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as moment from 'moment/moment';
import {MatSnackBar} from '@angular/material/snack-bar';

const AUTH_API = 'https://api.theomamori.com/api/social/google/';
//const AUTH_API = 'http://localhost:8080/api/social/google/';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
  // headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
};

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {

  private onSuccess;
  private onFailure;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
  }

  public initializeGoogleAuth() {
    try {
      window?.['google']?.accounts?.id?.initialize({
        client_id: '429354727507-eim1v0d3c1oiil578du2tf07h53v5tuq.apps.googleusercontent.com',
        callback: this.OnSuccess.bind(this),
        ux_mode: 'popup'
      });
    } catch (error) {
      console.error('<==== ERROR ===>', error);
    }
  }

  setDialogReference(successCallBack, errorCallBack) {
    this.onSuccess = successCallBack;
    this.onFailure = errorCallBack;
  }

  createButton(htmlElement, callBack, text?) {
    window?.['google']?.accounts?.id?.renderButton(
      htmlElement,
      {
        type: 'icon',
        theme: 'filled_blue',
        shape: 'square',
        size: 'large',
        text: text || 'signup_with',
        click_listener: callBack
      }
    );
  }

  OnSuccess(response) {
    return this.http.post(AUTH_API + 'signup', {
      token: response.credential,
      dt: moment().toDate()
    }, httpOptions).subscribe(
      (data: any) => {
        this.onSuccess(data);
      },
      (err) => {
        this.onFailure(err);
        this.snackBar.open('Something went wrong', 'X', {duration: 1000});
      }
    );
  }
}
