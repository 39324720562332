import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/services/auth.guard';

const routes: Routes = [
  {
    path: '',
    data: { title: 'Home', description: `Unlock the joy of life's milestones with The Omamori! Your premier destination for wedding and baby registry bliss. Start your journey today!` },
    loadChildren: () =>
      import('./pages/registry/home/home.module').then((res) => res.HomeModule),
  },
  {
    path: 'linkedIn',
    loadChildren: () =>
      import('./pages/registry/home/home.module').then((res) => res.HomeModule),
  },
  {
    path: 'baby-registry',
    data: { title: 'Baby Registry', description: `Welcome to Our Baby Registry: Where Every Moment Matters! Find top-quality essentials for your bundle of joy's journey. Start now!` },
    loadChildren: () =>
      import('./pages/registry/baby/baby-registry.module').then(
        (res) => res.BabyRegistryModule
      ),
  },
  {
    path: 'login',
    data: { title: 'Login', description: `Seamless Access to Your Omamori Account! Log in effortlessly to manage your wedding and baby registries with ease. Your journey awaits!` },
    loadChildren: () =>
      import('./pages/auth/login/login.module').then((res) => res.LoginModule),
  },
  {
    path: 'sign-up',
    data: { title: 'Sign-up', description: `Join The Omamori Family Today! Sign up for access to our premier wedding and baby registry services. Let's make your milestones unforgettable!` },
    loadChildren: () =>
      import('./pages/auth/sign-up/sign-up.module').then(
        (res) => res.SignUpModule
      ),
  },
  {
    path: 'review',
    data: { title: 'Reviews', description: `Discover Heartwarming Reviews at The Omamori! See why our customers love our wedding and baby registry services. Join the joy today!` },
    loadChildren: () =>
      import('./pages/review/review.module').then((res) => res.ReviewModule),
  },
  {
    path: 'shop',
    data: { title: 'Shop', description: `Find the perfect gift for every milestone at our curated wedding and baby registry shop! Discover unforgettable experiences and cherished treasures.` },
    loadChildren: () =>
      import('./pages/shop/shop.module').then((res) => res.ShopModule),
  },
  {
    path: 'create-registry',
    data: { title: 'Create Registry', description: `Begin Your Registry Adventure! From wedding bliss to baby joy, personalize your perfect registry with us. Start crafting memories now.` },
    loadChildren: () =>
      import('./pages/registry/create/create-registry.module').then(
        (res) => res.CreateRegistryModule
      ),
  },
  {
    path: 'thankyou',
    data: { title: 'Thank you', description: 'Omamori thank you.' },
    loadChildren: () =>
      import('./pages/thank-you/thank-you.module').then(
        (res) => res.ThankYouModule
      ),
  },
  {
    path: 'sample-registry',
    data: { title: 'Sample Registry', description: `Explore Our Sample Registries for Baby and Wedding Bliss! Get inspired and start building your dream registry today.` },
    loadChildren: () =>
      import('./pages/registry/sample/sample.module').then(
        (res) => res.SampleRegistryModule
      ),
  },
  {
    path: 'cash-registry',
    data: { title: 'Cash Registry', description: `Empower Loved Ones to Gift Meaningfully with Our Cash Registry! Support dreams, experiences, and milestones. Create yours today!` },
    loadChildren: () =>
      import('./pages/registry/cash/cash-registry.module').then(
        (res) => res.CashRegistryModule
      ),
  },
  {
    path: 'wedding-registry',
    data: { title: 'Wedding Registry', description: `Say 'I Do' to Perfection with Our Wedding Registry! Discover timeless treasures to start your journey together. Begin creating memories today!` },
    loadChildren: () =>
      import('./pages/registry/wedding/wedding-registry.module').then(
        (res) => res.WeddingRegistryModule
      ),
  },
  {
    path: 'experiences',
    data: { title: 'Experiences', description: `Create unforgettable memories with our curated experiences on The Omamori! From prenatal yoga to romantic dates and prewedding financial vows to home organization, guests can gift you the perfect moments.` },
    loadChildren: () =>
      import('./pages/registry/experiences/experiences.module').then(
        (res) => res.ExperiencesModule
      ),
  },
  {
    path: 'product',
    data: { title: 'Product', description: `Explore Our Product Collection: Elevate Your Every Day with Quality and Style! Discover must-have items for every occasion. Shop now!` },
    loadChildren: () =>
      import('./pages/product/product.module').then((res) => res.ProductModule),
  },
  {
    path: 'product/:id',
    data: { title: 'Product Details', description: 'Omamori product description and review page.' },
    loadChildren: () =>
      import('./pages/product/product.module').then((res) => res.ProductModule),
  },
  {
    path: 'planning',
    data: { title: 'Planning', description: `Craft Your Dream Wedding Registry with Ease! Explore our comprehensive planning guide for a seamless registry experience. Start planning now!`},
    loadChildren: () =>
      import('./pages/planning/planning.module').then(
        (res) => res.PlanningModule
      ),
  },
  {
    path: 'blog/:name',
    data: { title: 'Blob', description: 'Omamori blog details.' },
    loadChildren: () =>
      import('./pages/planning/blogs/blogs.module').then(
        (res) => res.BlogsModule
      ),
  },
  {
    path: 'testimonial',
    data: { title: 'Testimonials', description: `Stories, Real Joy: Explore heartwarming testimonials at The Omamori! Discover why our community trusts us for life's precious moments.` },
    loadChildren: () =>
      import('./pages/testimonial/testimonial.module').then(
        (res) => res.TestimonialModule
      ),
  },
  {
    path: 'website',
    data: { title: 'Website', description: `Your Love Story, Your Way: Create a Custom Wedding Website Tailored to Your Unique Journey! Start building your dream celebration today.` },
    loadChildren: () =>
      import('./pages/website/website.module').then((res) => res.WebsiteModule),
  },
  {
    path: 'create-website/:template',
    data: { title: 'Create website', description: 'Create website dynamically here.' },
    loadChildren: () =>
      import('./pages/create-website/create-website.module').then(
        (res) => res.CreateWebsiteModule
      ),
  },
  {
    path: 'gift-tracker',
    data: { title: 'Gift Tracker', description: `Easily track and organize your wedding or baby gifts with The Omamori's Gift Tracker! Stay updated on what's been chosen and what's left, all in one paperless platform. Start tracking today!` },
    loadChildren: () =>
      import('./pages/gift-tracker/gift-tracker.module').then(
        (res) => res.GiftTrackerModule
      ),
  },
  {
    path: 'registry-profile',
    data: { title: 'Registry Profile', description: `Create Your Registry Profile Today at The Omamori! Seamlessly blend your wedding or baby journey with ease and elegance. Start now!` },
    loadChildren: () =>
      import('./pages/registry/profile/registry-profile.module').then(
        (res) => res.RegistryProfileModule
      ),
  },
  {
    path: 'registry-profile-preview/:template',
    data: { title: 'Registry profile preview', description: 'Registry profile preview details.' },
    loadChildren: () =>
      import(
        './pages/registry/profile-preview/registry-profile-preview.module'
      ).then((res) => res.RegistryProfilePreviewModule),
  },
  {
    path: 'empty',
    loadChildren: () =>
      import('./pages/auth/404/404.module').then((res) => res.NotFoundModule),
  },
  {
    path: 'cart-list',
    data: { title: 'Cart list', description: 'Omamori cart list details.' },
    loadChildren: () =>
      import('./pages/cart/list/list.module').then((res) => res.ListModule),
  },
  {
    path: 'cart-preview',
    data: { title: 'Cart preview', description: 'Omamori cart preview list details' },
    loadChildren: () =>
      import('./pages/cart/preview/preview.module').then(
        (res) => res.PreviewModule
      ),
  },
  {
    path: 'accounts',
    data: { title: 'Accounts', description: 'Omamori logged user details page' },
    loadChildren: () =>
      import('./pages/account/account.module').then((res) => res.AccountModule),
  },
  {
    path: 'careers',
    data: { title: 'Careers', description: `Discover Your Dream Career at The Omamori Wedding and Baby Registry! Join our team and embark on a fulfilling journey with us. Apply now!` },
    loadChildren: () =>
      import('./pages/footer/Careers/careers.module').then(
        (res) => res.CareersModule
      ),
  },
  {
    path: 'help-center',
    data: { title: 'Help Center', description: `Find Answers to Your Questions at Our Help Center! Expert guidance and support for all your registry needs. Get started now.` },
    loadChildren: () =>
      import('./pages/footer/help-center/help-center.module').then(
        (res) => res.HelpCenterModule
      ),
  },
  {
    path: 'privacy-policy',
    data: { title: 'Privacy Policy', description: `Your Privacy, Our Priority: Explore The Omamori's transparent and secure Privacy Policy to safeguard your cherished moments.` },
    loadChildren: () =>
      import('./pages/footer/privacy policy/privacy.module').then(
        (res) => res.PrivacyPolicyModule
      ),
  },
  {
    path: 'general-info',
    data: { title: 'General Information', description: `Unlock the Secrets to Seamless Celebrations with The Omamori! Your go-to destination for all things wedding and baby registry. Start planning today!` },
    loadChildren: () =>
      import('./pages/footer/footer-common-view/footer-common.module').then(
        (res) => res.FooterCommonModule
      ),
  },
  {
    path: 'd1/:name',
    data: { title: 'Template', description: 'Omamori website template description.' },
    loadChildren: () =>
      import('./pages/templates/d1/d1.module').then((res) => res.D1Module),
  },
  {
    path: 'd2/:name',
    data: { title: 'Template', description: 'Omamori website template description.' },
    loadChildren: () =>
      import('./pages/templates/d2/d2.module').then((res) => res.D2Module),
  },
  {
    path: 'd3/:name',
    data: { title: 'Template', description: 'Omamori website template description.' },
    loadChildren: () =>
      import('./pages/templates/d3/d3.module').then((res) => res.D3Module),
  },
  {
    path: 'd4/:name',
    data: { title: 'Template', description: 'Omamori website template description.' },
    loadChildren: () =>
      import('./pages/templates/d4/d4.module').then((res) => res.D4Module),
  },
  {
    path: 'd5/:name',
    data: { title: 'Template', description: 'Omamori website template description.' },
    loadChildren: () =>
      import('./pages/templates/d5/d5.module').then((res) => res.D5Module),
  },
  {
    path: 'h1/:name',
    data: { title: 'Template', description: 'Omamori website template description.' },
    loadChildren: () =>
      import('./pages/templates/h1/h1.module').then((res) => res.H1Module),
  },
  {
    path: 'h2/:name',
    data: { title: 'Template', description: 'Omamori website template description.' },
    loadChildren: () =>
      import('./pages/templates/h2/h2.module').then((res) => res.H2Module),
  },
  {
    path: 'h3/:name',
    data: { title: 'Template', description: 'Omamori website template description.' },
    loadChildren: () =>
      import('./pages/templates/h3/h3.module').then((res) => res.H3Module),
  },
  {
    path: 'h4/:name',
    data: { title: 'Template', description: 'Omamori website template description.' },
    loadChildren: () =>
      import('./pages/templates/h4/h4.module').then((res) => res.H4Module),
  },
  {
    path: 'h5/:name',
    data: { title: 'Template', description: 'Omamori website template description.' },
    loadChildren: () =>
      import('./pages/templates/h5/h5.module').then((res) => res.H5Module),
  },
  {
    path: 'cash-template',
    data: { title: 'Cash template', description: 'Omamori cash template descriptions.' },
    loadChildren: () =>
      import('./pages/shop/cash_templates/cash.module').then(
        (res) => res.CashModule
      ),
  },
  {
    path: 'contact-us',
    data: { title: 'Contact Us', description: `Get in Touch with The Omamori Team Today! We're here to assist you with any questions or assistance you need. Reach out now!` },
    loadChildren: () =>
      import('./pages/contact-us/contact-us.module').then(
        (res) => res.ContactUsModule
      ),
  },
  {
    path: 'vendor-registration',
    data: { title: 'Vendor Registration', description: `Join our vibrant community of vendors at The Omamori! Sign up now for a seamless process – just visit our website, fill out the form, and we'll be in touch soon!` },
    loadChildren: () =>
      import('./pages/vendor-registration/vendor-registration.module').then(
        (res) => res.VendorRegistrationModule
      ),
  },
  { path: '**', redirectTo: '' },
];
/*todo remove cash-template*/

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
