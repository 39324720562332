import {Component, OnInit}                  from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef}            from '@angular/material/dialog';
import {Router} from '@angular/router';

import {SwiperOptions}       from 'swiper';
import {AuthService}         from '../../../../services/auth.service';
import {LinkedInAuthService} from '../../../../services/linkedin-auth.service';
import {StorageS}            from '../../../../services/storage';
import {TokenStorageService} from '../../../../services/token-storage.service';
import {SignUpComponent}     from '../sign-up/sign-up.component';
import {GoogleAuthService}   from '../../../../services/google-auth.service';
import {FacebookAuthService} from '../../../../services/facebook-auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
  public showLogin: boolean;
  public form: FormGroup;
  model: any = {
    email: null,
    password: null,
    confirmPassword: null,
    code: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  resend = false;
  loading           = false;
  isLogin           = true;
  isForgetPassword  = false;
  successMessage      = '';

  config: SwiperOptions = {
    pagination: {el: '.swiper-pagination', clickable: true},
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30,
  };

  constructor(
    private _formBuilder: FormBuilder, private authService: AuthService, public storageS: StorageS,
    private tokenStorage: TokenStorageService, public dialog: MatDialog,
    private dialogRef: MatDialogRef<LoginComponent>, public router: Router,
    private _googleAuthService: GoogleAuthService, private _facebookAuthService: FacebookAuthService,
    private _linkedInAuthService: LinkedInAuthService
  ) {
    this.showLogin = true;
    this.buildFormForLogin();
  }

  ngOnInit() {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
    }
    this._googleAuthService.setDialogReference(this.processLogin.bind(this), this.handleError.bind(this));
    this._facebookAuthService.setDialogReference(this.processLogin.bind(this), this.handleError.bind(this));
    this._linkedInAuthService.setDialogReference(this.processLogin.bind(this), this.handleError.bind(this));
    this._googleAuthService.createButton(
      document.getElementById('google-signin'),
      () => {
      },
      'signin_with'
    );
  }

  linkedIn(){
    window.localStorage.setItem('login', 'signin');
    this._linkedInAuthService.logIn();
  }

  loginWithFB() {
    this._facebookAuthService.login();
  }

  save(): void {
    this.model.email = this.form.controls.email.value;
    this.model.password = this.form.controls.password.value;

    this.authService.login(this.model.email, this.model.password).subscribe(
      (data) => {
        this.processLogin(data);
      },
      (err) => {
        this.handleError(err);
      }
    );
  }

  processLogin(data){
    this.storageS.getByCustom({ci: data.id}, 'registry').subscribe((response) => {
      data.ru = response.data && response.data[0] && response.data[0].id;
      data.tem = response.data && response.data[0] && response.data[0].tem;
      this.tokenStorage.saveToken(data.accessToken);
      this.tokenStorage.saveSession(this.tokenStorage.USER_KEY, data);
      this.isLoginFailed = false;
      this.isLoggedIn = true;
      this.close();
      if (data.ru) {
        this.router.navigateByUrl(`registry-profile`);
      } else {

        this.router.navigateByUrl('/create-registry');
      }
    }, error => {
      console.log(error);
    });
  }

  handleError(err){
    this.errorMessage = err.error.message;
    this.isLoginFailed = true;
  }

  reloadPage(): void {
    window.location.reload();
  }

  signUpDialog() {
    this.dialog.open(SignUpComponent, {
      panelClass: 'dialog_outer',
      closeOnNavigation: false,
      data: []
    });
  }

  goToSignUp() {
    this.dialogRef.close();
    this.signUpDialog();
  }

  close() {
    this.dialogRef.close();
  }
  private buildFormForLogin(){
    this.form = this._formBuilder.group({
                                          email   : [
                                            this.model.email,
                                            [
                                              Validators.required,
                                              Validators.minLength(2),
                                              Validators.maxLength(60),
                                            ],
                                          ],
                                          password: [
                                            this.model.password,
                                            [
                                              Validators.required,
                                              Validators.minLength(2),
                                              Validators.maxLength(20),
                                            ],
                                          ]
                                        });
  }

  private buildFormForForgetPassword(){
    this.form = this._formBuilder.group({
                                          email          : [
                                            this.model.email,
                                            [
                                              Validators.required,
                                              Validators.minLength(2),
                                              Validators.maxLength(60),
                                            ],
                                          ],
                                          password       : [
                                            this.model.password,
                                            [
                                              Validators.required,
                                              Validators.minLength(2),
                                              Validators.maxLength(20),
                                              this.verifyMismatch.bind(this)
                                            ],
                                          ],
                                          confirmPassword: [
                                            this.model.confirmPassword,
                                            [
                                              Validators.required,
                                              Validators.minLength(2),
                                              Validators.maxLength(20),
                                              this.verifyMismatch.bind(this)
                                            ],
                                          ],
                                          code: [
                                            this.model.code,
                                            [
                                              Validators.required,
                                              Validators.minLength(4),
                                              Validators.maxLength(4)
                                            ],
                                          ]
                                        });
  }

  private verifyMismatch(formCtrl: any){

    if(formCtrl){
      const password        = this.form?.get('password')?.value;
      const confirmPassword = this.form?.get('confirmPassword')?.value;

      if(!password || !confirmPassword){
        return null;
      } else if(password !== confirmPassword){
        return {mismatch: true};
      }
    }
    return null;
  }

  onUpdatePassword(): void{
    const {email, password, code} = this.form.value;
    this.authService.updatePassword(email, password, code).subscribe(
        (data) => {
          this.processLogin(data);
        },
        (err) => {
          this.errorMessage  = err.error.message;
          this.isLoginFailed = true;
        }
    );

  }

  onSubmit(){
    if(this.isLogin){
      this.save();
    } else if(this.isForgetPassword){
      this.onUpdatePassword();
    }
  }

  sendCode(){
    const {email, code} = this.form.value;
    this.successMessage = '';
    this.resend = false;
    this.loading = true;
    this.authService.sendCode(email, code).subscribe(
        (data) => {
          this.successMessage = data.message;
          this.isLoginFailed = false;
          this.resend = true;
          this.loading = false;
        },
        (err) => {
          this.successMessage = '';
          this.errorMessage  = err.error.message;
          this.isLoginFailed = true;
          this.resend = true;
          this.loading = false;
        }
    );
  }

  setButtonLabel(){
    if(this.isLogin){
      return 'Login';
    } else if(this.isForgetPassword){
      return 'Update password';
    }
  }

  switchLinks(str: string){
    if(str == 'login'){
      this.isLogin          = true;
      this.isForgetPassword = false;
      this.buildFormForLogin();
    }else{
      this.isForgetPassword = true;
      this.isLogin          = false;
      this.buildFormForForgetPassword();
    }

    this.errorMessage = '';
    this.isLoginFailed = false;
  }

}
