<div class="main-frame login">

  <!-- <button tabindex="-1" class="close-me" mat-button mat-mini-fab (click)=close()> - keerthika
    <span class="ev-icon uil-times-circle"></span>
  </button> -->

  <div class="inner-frame">
    <div class="right-side active">
      <!-- pasted here -->
      <button tabindex="-1" class="close-me" mat-button mat-mini-fab (click)=close()>
        <span class="ev-icon uil-times-circle"></span>
      </button>
      <div class="content-top">
        <div class="content-title">
          <span class="content-ball theme-orange"></span>
          <span>Welcome to <strong>Omamori</strong></span>
        </div>
      </div>

      <div class="form-sections" novalidate>
        <form [formGroup]="form" novalidate>
          <div class="row-section">
            <div>
              <input matInput required type="text" class="form-control" name="email" formControlName="email"
                     placeholder="Email"/>
            </div>
            <span class="link forgot-password" *ngIf="isForgetPassword && form?.controls?.email?.valid && !loading" (click)="sendCode()" >{{resend ? 'Re':''}}Send Verification Code</span>
            <errors [field]="form?.controls?.email"></errors>
          </div>
          <div class="row-section" *ngIf="successMessage">
            <div>
              <input matInput type="number" formControlName="code" class="form-control" placeholder="Verification Code"/>
            </div>
            <errors [field]="form?.controls?.code"></errors>
            <div class="mb-3 alert-success" *ngIf="successMessage">
              {{successMessage}}
            </div>
          </div>
          <div class="row-section" *ngIf="!isForgetPassword || successMessage">
            <div>
              <input type="password" matInput required  placeholder="{{isForgetPassword ? 'New Password':'Password'}}" class="form-control" name="password"
                     formControlName="password" minlength="6"/>
            </div>
            <errors [field]="form?.controls?.password"></errors>
          </div>
          <div class="row-section" *ngIf="successMessage">
            <div>
              <input matInput type="password" class="form-control" name="confirmPassword" formControlName="confirmPassword"
                     placeholder="Confirm Password"/>
            </div>
            <errors [field]="form?.controls?.confirmPassword"></errors>
          </div>

          <div class="row-section align-center">
            <button mat-raised-button [disabled]="loading || form.invalid" (click)="onSubmit()">{{setButtonLabel()}}</button>
            <span class="link forgot-password" (click)="switchLinks('forget')" *ngIf="isLogin">Forgot password?</span>
            <span class="link forgot-password" (click)="switchLinks('login')" *ngIf="!isLogin || isForgetPassword">Login</span>
            <!--<div style="text-align: left; width: 100%;">
              <a href="" class="forgot-password">Forgot password?</a>
            </div>-->
          </div>

          <div class="alert alert-danger" role="alert" *ngIf="isLoginFailed"> Login
            failed: {{ errorMessage }} </div>
          <div class="alert alert-success" *ngIf="isLoggedIn"> Logged in as .</div>
          <div class="row-line">
            <span class="lines"></span>
            <span>or</span>
            <span class="lines"></span>
          </div>

          <div class="other-login-options">
            <span>Log In with</span>
            <!--<img (click)="loginWithFB()"
              src="assets/images/misc/facebook.png"
              alt="facebook"
            />
            <span>or</span>-->
            <!--Google signin button auto generate-->
            <div id="google-signin"></div>
            <span>or</span>
            <img (click)="linkedIn()"
                 src="../../../../assets/images/linkedin.png" alt="linkedin" />
          </div>

          <div class="no-account">
            <span>Don't have an account?</span>
            <span class="link mli-8" (click)="goToSignUp()">Sign Up</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
