import {Injectable} from '@angular/core';

const TOKEN_KEY = 'client-auth-token';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  public USER_KEY = 'client-auth-user';
  public CART_KEY = 'client-cart';
  public PRODUCT_KEY = 'client-product-view';
  public PRODUCT_VARIANTS = 'client-product-variants';
  public BLOG_KEY = 'client-blog-view';
  public SITE_PAGE_ID = 'site-page-id';
  public SHOP_KEY = 'client-shop';
  public POLICY = 'client-policy';

  constructor() {
  }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveSession(key: string, user: any): void {
    window.sessionStorage.removeItem(key);
    window.sessionStorage.setItem(key, JSON.stringify(user));
  }

  public getSession(key): any {
    const details = window.sessionStorage.getItem(key);
    if (details) {
      return JSON.parse(details);
    }
    return {};
  }
}
